<template>
  <div id="combos-view">
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <template v-else>
      <div class="vx-row">
        <div class="vx-col w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/6">
          <a href="#" class="stat_btn" :class="{'current': current === 'all'}" @click="getItems('all')">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DatabaseIcon"
              icon-right
              :statistic="counts('all')"
              :statisticTitle="$t('all')" />
          </a>
        </div>
        <div class="vx-col w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/6">
          <a href="#" class="stat_btn" :class="{'current': current === 'active'}" @click="getItems('active')">
            <statistics-card-line
              role="button"
              hideChart
              class="mb-base"
              icon="CloudIcon"
              icon-right
              :statistic="counts('active')"
              :statisticTitle="$t('active')"
              color="warning" />
          </a>
        </div>
        <div class="vx-col w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/6">
          <a href="#" class="stat_btn" :class="{'current': current === 'passive'}" @click="getItems('passive')">
            <statistics-card-line
              role="button"
              hideChart
              class="mb-base"
              icon="CloudOffIcon"
              icon-right
              :statistic="counts('passive')"
              :statisticTitle="$t('passive')"
              color="passive" />
          </a>
        </div>
        <div class="vx-col w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/5 xl:w-1/6">
          <a href="#" class="stat_btn" :class="{'current': current === 'sale'}" @click="getItems('sale')">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="BookmarkIcon"
              icon-right
              :statistic="counts('sale')"
              :statisticTitle="$t('sale')"
              color="danger" />
            </a>
        </div>
        <div class="vx-col w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/6">
          <a href="#" class="stat_btn" :class="{'current': current === 'express'}" @click="getItems('express')">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="TruckIcon"
              icon-right
              :statistic="counts('express')"
              :statisticTitle="$t('express')"
              color="success" />
          </a>
        </div>
        <div class="vx-col w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/6">
          <a href="#" class="stat_btn" :class="{'current': current === 'sold'}" @click="getItems('sold')">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="AlertTriangleIcon"
              icon-right
              :statistic="counts('sold')"
              :statisticTitle="$t('soldOut')"
              color="danger" />
          </a>
        </div>
      </div>
    </template>
    <vx-card v-if="!isLoading">
      <vs-table ref="table" :data="filteredItems" :max-items="itemsPerPage">
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
          <div class="flex flex-wrap-reverse items-center">
            <router-link v-if="$acl.check('admin')" :to="{name: 'comboCreate'}" class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary">
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">{{ $t('add_new') }}</span>
            </router-link>
          </div>
          <div>
            <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">{{ currentx * filter.limit - (filter.limit - 1) }} - {{ collections.pagination.all - currentx * filter.limit > 0 ? currentx * filter.limit : collections.pagination.all }} of {{ collections.pagination.all }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item v-for="opt in perPageOptions" :key="opt" @click="filter.limit=opt">
                  <span>{{ opt }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
            <div class="inline-block relative mb-4">
              <vs-input :placeholder="$t('search')" class="custom-search-input" v-model="searchQuery" />
              <feather-icon v-if="searchQuery.length" icon="TrashIcon" svgClasses="w-4 h-4 hover:text-danger stroke-current" style="position: absolute !important; top: 14px; right: 13px" class="ml-2" @click.stop="searchQuery = ''" />
            </div>
          </div>
        </div>
        <template slot="thead">
          <vs-th class="w-4">{{ $t('fields.image') | capitalize }}</vs-th>
          <vs-th class="w-4">{{ $t('fields.id') }}</vs-th>
          <vs-th class="w-4">{{ $t('fields.code') }}</vs-th>
          <vs-th sort-key="name_en" class="max-w-xs">{{ $t('fields.title') }}</vs-th>
          <vs-th sort-key="name_en" class="max-w-xs">{{ $t('fields.deadlines') }}</vs-th>
          <vs-th sort-key="popularity">{{ $t('popularity') }}</vs-th>
          <vs-th class="hidden xl:block">{{ $t('fields.active') }}</vs-th>
          <vs-th>{{ $t('fields.type') }}</vs-th>
          <vs-th>{{ $t('fields.price') }}</vs-th>
          <!-- <vs-th>Items</vs-th> -->
          <vs-th>{{ $t('fields.stock') }}</vs-th>
          <vs-th>{{ $t('fields.actions') }}</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(tr, indextr) in data" :key="indextr" class="pt-3" :class="['pt-3', !tr.isActive ? 'text-danger' : '']" :state="!data[indextr].canPublished ? 'danger' : ''">
            
            <vs-td v-if="tr.images">
              <div class="img" :class="{'sale': data[indextr].discount}" role="button" @click="showData(tr.uuid)">
                <img v-if="tr.images[0]" :src="`${$baseUrl}/collections/preview/${tr.images[0].uuid}.webp`" alt="">
              </div>
            </vs-td>
            <vs-td :data="data[indextr].code">
              {{ data[indextr].code }} 
            </vs-td>
            <vs-td :data="data[indextr].bar_code">
              {{ data[indextr].bar_code }}
            </vs-td>
            <vs-td :data="data[indextr].name_en">
              {{ data[indextr][`name_${$i18n.locale}`] }}
            </vs-td>
            <vs-td :data="data[indextr].from">
              <template v-if="data[indextr].from">
                {{ new Date(data[indextr].from).toLocaleString('ru-RU') }}
                <br> 
                {{ new Date(data[indextr].to).toLocaleString('ru-RU') }}
              </template>
              <span v-else> No data! </span>
            </vs-td>
            <vs-td :data="data[indextr].popularity">
              <vs-progress :percent="(data[indextr].likes ? data[indextr].likes[0] : 0)" :color="getPopularityColor(data[indextr].likes ? data[indextr].likes[0] : 0)" class="shadow-md" />
            </vs-td>

            <vs-td :data="data[indextr].isActive" class="hidden xl:table-cell">
              <vs-chip :color="data[indextr].isActive ? 'primary' : 'danger'">
                <feather-icon v-if="data[indextr].isActive" icon="CheckIcon" svgClasses="w-5 h-5" @click.stop="editData(tr)" />
                <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" @click.stop="editData(tr)" />
              </vs-chip>
            </vs-td>

            <vs-td :data="data[indextr].type">
              <vs-chip :color="data[indextr].type === 'both' ? 'success' : data[indextr].type === 'market' ? 'primary' : 'warning'">
                <template v-if="data[indextr].type">{{data[indextr].type}}</template>
              </vs-chip>
            </vs-td>

            <vs-td class="font-semibold" :data="data[indextr].price">
              {{ data[indextr].price }}
            </vs-td>

            <vs-td :data="data[indextr].items" class="font-semibold" :class="{'text-danger': data[indextr].stock_quantity < data[indextr].stock_min}">
              {{ data[indextr].stock_quantity }}
            </vs-td>

            <vs-td class="whitespace-no-wrap">
              <feather-icon icon="EyeIcon" svgClasses="w-5 h-5 hover:text-success stroke-current" @click.stop="showData(tr.uuid)" />
              <feather-icon v-if="$acl.check('admin')" icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="editData(tr.uuid)" />
              <!-- <feather-icon v-if="$acl.check('admin')" icon="RepeatIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="changeStatus(tr.uuid, tr.isActive)" /> -->
              <!-- <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData(tr.id)" /> -->
            </vs-td>
          </vs-tr>
        </template>

      </vs-table>
      <vs-row class="mt-4">
        <vs-pagination :total="pages" :max="9" v-model="currentx" @change="paginateTo"></vs-pagination>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
export default {
  components: {
    StatisticsCardLine: () => import('@/components/statistics-cards/StatisticsCardLine.vue')
  },
  data () {
    return {
      collections: [],
      current: 'all',
      itemsPerPage: 10,
      isLoading: true,
      isMounted: false,
      perPageOptions: [4, 10, 15, 20, 50],
      currentx: Number(this.$route.query.page || 1),
      searchQuery: '',
      itemCounts: null,
      filter: {
        limit: Number(this.$route.query.limit || 15),
        offset: Number(this.$route.query.limit || 15) * (Number(this.$route.query.page || 1) - 1),
        keyword: '',
        isActive: null,
        canPublished: null,
        hasDiscount: null,
        type: null,
        isEnoughInStock: null
      }
    }
  },
  watch: {
    filter: {
      handler () { this.fetchData() },
      deep: true
    },
    searchQuery (newVal) {
      if (newVal.length > 2) {
        this.filter.keyword = newVal
        this.currentx = 1
      }
      if (!newVal) { this.filter.keyword = '' }
    }
  },
  computed: {
    pages () { return this.collections.pagination && this.collections.pagination.all ? Math.ceil(this.collections.pagination.all / this.filter.limit) : 1 },
    filteredItems () { return this.collections.data },
    counts () { return (status) => { return this.isLoading ? 0 : this.itemCounts[status] } }
  },
  async created () {
    await this.fetchData()
    await this.getCounts()
    this.isLoading = false
    // this.isMounted = true
  },
  methods: {
    async fetchData () {
      await this.$http.get('/collections', { params: this.filter }).then(response => {
        this.collections = response.data
      }).catch(err => { this.notify('error', this.$t('notify.error'), err.message) })
    },
    async getCounts () {
      await this.$http.get('/statistics/item-counts', { params: { model: 1 } })
        .then(response => { this.itemCounts = response.data }).catch(err => { this.notify('error', this.$t('notify.error'), err.message) })
    },
    getPopularityColor (num) {
      if (num > 80)  return 'success'
      if (num > 60)  return 'primary'
      if (num >= 40) return 'warning'
      if (num < 40)  return 'danger'
      return 'primary'
    },
    editData (id) { this.$router.push(`/combo-products/${id}/edit`) },
    showData (id) { this.$router.push(`/combo-products/${id}`) },
    changeStatus (id, status) {
      this.$http.patch(`/collections/edit/${id}`, { isActive: !status }).then(response => {
        if (response.status === 200) {
          this.notify('success', this.$t('notify.success'), `${this.$t('comboProduct')} ${this.$t('notify.sUpdated')}`)
          this.fetchData()
        }
      }).catch(err => { this.notify('error', this.$t('notify.error'), err.message) })
    },
    getItems (e) {
      this.current = e
      this.currentx = 1
      this.filter.offset = 0
      this.filter.isActive = null
      this.filter.canPublished = null
      this.filter.hasDiscount = null
      this.filter.isEnoughInStock = null
      this.filter.type = null
      switch (e) {
      case 'all':
        break
      case 'active':
        this.filter.isActive = true
        this.filter.canPublished = true
        break
      case 'passive':
        this.filter.isActive = false
        break
      case 'sale':
        this.filter.hasDiscount = true
        break
      case 'express':
        this.filter.type = 'express'
        break
      case 'sold':
        this.filter.isEnoughInStock = false
        break
      default:
        break
      }
    },
    paginateTo (e) {
      if (this.isMounted) {
        this.filter.offset = this.filter.limit * (this.currentx - 1)
        const query = { ...this.$route.query, page: e }
        this.$router.replace({ query })
      } else {
        this.isMounted = true
      }
    },
    notify (type, title, text) {
      this.$vs.notify({
        title,
        text,
        iconPack: 'feather',
        icon: type === 'success' ? 'icon-check-circle' : 'icon-alert-circle',
        color: type === 'success' ? 'primary' : 'danger'
      })
    }
  }
}
</script>

<style lang="scss">
#combos-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }
    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      // margin-left: 1.5rem;
      // margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }
  }
  .stat_btn {
    color: #626262;
    .vx-card {
      border: 1px solid transparent;
    }
    &:hover {
      cursor: pointer;
      color: #7367F0;
      .vx-card {
        border: 1px solid #666666;
      }
    }
    &:active {
      .vx-card {
        border: 1px solid green;
      }
    }
    &.current {
      color: #7367F0;
      .vx-card {
        border: 1px solid #7367F0;
      }
    }
  }
  .img {
    width: 60px;
    height: 60px;
    background-color: lightgray;
    border-radius: 4px;
    overflow: hidden;
    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
    &.sale {
      outline: rgba(234,84,85,0.8) solid 3px;
      box-shadow: 0px 0px 15px rgba(234,84,85,1);
    }
  }
}
</style>